
/* ------------  General Site Responsive ----------- */
@media all and (min-width: 1201px)  {
	#MobileNavigation{
		display: none !important;
	}	
	.SportsPage #MobileNavigation{
		display: block !important;
	}
}
@media all and (max-width: 1200px)  {
	#MenuIcon{
		display: block !important;
	}
	#Menu{
		display: none !important;
	}
	.PageResults .banner, .InnerPage .banner {
	    height: 73vh;
	    margin-top: 90px;
	}
	.homebtn img{
	    height: 61px;
	}
	.PageBanner{
		margin-top: 67px !important;
	}
	.TopSection {
   		padding-top: 65px !important;
	}
	img.innerLogo {
	    height: 46px;
	    margin-top: 10px;
	}
	.PageResults .banner, .InnerPage .banner {
	    height: 54vh;
	}
}

@media all and (max-width: 786px)  {
#home3 h2{
	font-size: 2em;
}
	.tacMid{
		text-align: center !important;
	}
	.inner.pl20.pr10{
		padding-left: 10px !important;
	}
	.clearMid{
		width: 100%;
		padding: 0px !important;
		clear: both;
	}
}
/*** Newsletters Page Responsive ***/
@media all and (max-width: 726px)  {
	.NewsletterItem{
		width: 50% !important;
	}

	#mobSlider{
		display: block;
	}
	.staticImages{
		display: none !important;
	}
}
@media all and (max-width: 500px)  {
    .NewsletterItem{
		width: 100% !important;
	}
}
/*** Newsletters Page Responsive ***/

/*** Staff Responsive ***/
@media all and (max-width: 520px)  {
	.StaffPage .staffProfile .profilePic {
	    margin: 0 0px 30px 0 !important;
	    float: left;
	    width: 100%;
	    clear: both;
	    display: block;
	}
}
/*** Staff Responsive ***/
/*** Gallery Responsive ***/

@media all and (max-width: 800px)  {
    .galleryImage{
        width: 50% !important;
        padding-bottom: 50% !important;
    }
}
@media all and (max-width: 768px)  {
	.grid-item, .grid-sizer { 
		width: 50% !important; 
	}

	.flex{
		display: block;
	}
	.col-9.clearMid.typography{
		padding-left: 0px;
	} 
}
@media all and (max-width: 500px)  {
    .galleryImage{
        width: 100% !important;
        padding-bottom: 100% !important;
    }
}

@media all and (max-width: 950px)  {
	.TwoColumns{
		clear: both !important;
		width: 100% !important;
	}
}
@media all and (max-width: 450px)  {
	.grid-item, .grid-sizer { 
		width: 100% !important; 
	}
}
/*** Gallery Responsive ***/

/* ------------  General Site Responsive ----------- */


@media all and (max-width: 1150px)  {
	.WelcomeSection{
		text-align: center;
	}
	.WelcomeCalButton,
	.WelcomeButton {
		margin: 0 auto;
		clear: both;
		bottom: auto !important;
	    height: 130px;
	    width: 130px;
	    right: auto;
	    position: relative;
	    @include transform(translate(0px, 0px));
	}
	.WelcomeButton {
	margin-bottom: 20px !important;
	}
	.WelcomeText {
	    padding-right: 0px;
	}
}
@media all and (max-width: 1030px)  {
	.TopSection .banner {
		position: relative !important;
		float: left !important;
		width: 100% !important;
		height: 100vh !important;
		min-height: 0px !important;
	}
	.WelcomeSection{
	    position: relative;
	    clear: both;
	    float: left;
	    bottom: auto;
	}
	.TopSection{
		height: auto !important;
		.col-8{
			width: 100% !important;
		}
		.col-4{
			display: none;
		}
	}
	.parallax{
		@include transform(translateY(0px) !important);
		margin-top: 0px !important;
	}
	*{
		background-attachment: initial !important;
		-o-background-attachment: initial !important;
		-ms-background-attachment: initial !important;
		-moz-background-attachment: initial !important;
		-webkit-background-attachment: initial !important;
	}
}
@media all and (max-width: 1400px)  {
	#home5 .calendarEvent * {
	    display: inline-block;
	    vertical-align: middle;
	    color: #f6952d;
	    margin: 5px 5px;
	}
}

@media all and (max-width: 1187px)  {
	#home5{
		.col-6{
			width: 100% !important;
			clear: both;
		}
	}

	.innerHome5{
		float: none;
		margin: 0 auto;
		min-width: 0px;
		max-width: 100%;
		width: 700px;
		.col-6:first-of-type{
			margin-bottom: 50px;
		}
	}
}

@media all and (max-width: 666px)  {
	#home7 .col-6{
		width: 100%;
	}
}


@media all and (max-width: 768px)  {
	#home2 * {
    	font-size: 1.3em;
    }	
    #home7 {
	    padding: 50px 20px;
	}


}
@media all and (max-width: 626px)  {
	#home5 .calendarEvent * {
	    display: block !important;
	    text-align: center;
	}
	#home5{

		text-align: center;
	}
	#home5 .calendarEvent{

	    text-align: center;
		.divider,
		i{
			display: none !important;
		}
		img{
		    display: inline-block !important;
		    float: none;
		    position: relative;
		    margin: 0 auto;
		}
	}
}


/*** Sports Inner Page ***/
@media all and (max-width: 1350px)  {
	.SportBox{width: 33.33% !important;}
}
@media all and (max-width: 880px)  {
	.SportBox{width: 50% !important;}
}
@media all and (max-width: 650px)  {
	.SportsInnerPage{
		padding-right: 0px !important;
		.MainSite,
		.SportsLogo{
			position: absolute !important;
		}
	}
	#SportsInnerPageWrapper{
		margin-top: 70px;
	}
}
@media all and (max-width: 530px)  {
	.SportBox{width: 100% !important;}
}
/*** Sports Inner Page ***/

/*** Sports Page ***/
@media all and (max-width: 1000px)  {

	.flipItem{
		text-align: center !important;
	}
	.single.container-1{
		width: 100% !important;
	}
	.sportsSection .flipItem.flip-1 {
		width: 100% !important;
		height: auto !important;
	}
	#SportsWrapperInner{
		width: 100% !important;
		overflow-x: hidden !important;
		overflow-y: auto !important;
	}
	.sportsSection{
		width: 100% !important; 
		height: auto !important;
		.flipItem{
			clear: none !important;
			min-width: 0px !important;
			width: 50% !important;
			padding-bottom: 50% !important;
		}
	}
	.flipItem{
		opacity: 1 !important;
		@include transform(translateX(0%) rotateY(0deg) !important);
	}
}
@media all and (max-width: 650px)  {
	.sportsSection .flipItem{
		clear: none !important;
		min-width: 0px !important;
		width: 100% !important;
		padding-bottom: 100% !important;
	}
	#SportsWrapper {
	    float: left;
	    width: 100%  !important;
		margin-top: 70px;
		border-top: 10px solid #161941;
	}
	.SportsLogo{
	    left: 10px;
	    top: 10px;
	    right: auto;
	    width: 70px;
	}
	.MainSite{
		top: 20px;
		@include transform(translate(0px));
	}
	#sidebar .sidebar-heading a {
    	font-size: 0.8em;
	}
}
/*** Sports Page ***/

/* iPad */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
	.GovernorContainer .col-6.p10.clearMid {
	    width: 50%;
	    float: left;
	    clear: none;
	}
}


@media all and (max-width: 500px)  {
	.homebtn img {
	    height: 43px;
	    margin-top: 10px;
	    margin-bottom: 7px;
	}
	img.innerLogo {
	    height: 33px;
	    margin-top: 14px;
	    margin-bottom: 14px;
	}

	#header {
	    text-align: left;
	    padding-left: 20px;
	}
	
	.ofstedLogo{
		left: auto;
		right: 120px;
		width: 40px; 
	}
}


/* iPad Landscape */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) { 
	#home5 .col-6{
		width: 50% !important;
		clear: none !important;
	}
	.innerHome5 {
	    width: 100% !important;
	}

/*
.WelcomeSection{
	z-index: 90;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	padding: 20px 10%;
	bottom: 0px;
	background-color: #fff;
	*{
	text-align: left !important;
	}
}
.WelcomeText{
	padding-right: 300px;
}

.WelcomeText .WelcomeCalButton,
.WelcomeText .WelcomeButton{
	position: absolute;
	right: 70px;
	bottom: 20px !important;
	width: 130px;
	@include transform(translateY(60%));
	*{
	text-align: center !important;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.WelcomeButtonInner{
		padding-bottom: 100%;
		img{
			width: 40%;
    		margin-top: 10px;
		}
	}
	.centerButton{
		width: 100%;
		text-align: center; 
		position: absolute;
		top: 50%;
		color: #fff;
		left: 50%;
		font-size: 1.2em;
		*{
			color: #fff;
			font-family: 'Playfair';
		}
		@include transform($center);
	}
}

	.WelcomeText .WelcomeCalButton,
	.WelcomeText .WelcomeButton{
		right: 110px;
	}
	.WelcomeText.WelcomeCalButton{
	    right: 0px;
	}
	*/
}

/* iPad Portrait */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {

/*
.WelcomeSection{
	z-index: 90;
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	padding: 20px 10%;
	
    bottom: -3px !important;

	background-color: #fff;
	*{
	text-align: left !important;
	}
}
.WelcomeText{
	padding-right: 300px;
}

.WelcomeText .WelcomeButton{
	position: absolute;
	right: 70px;
	bottom: 20px !important;
	background-color: #d6272a;
	width: 130px;
	@include transform(translateY(60%));
	*{
	text-align: center !important;
	}
	a{
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
	}
	.WelcomeButtonInner{
		padding-bottom: 100%;
		img{
			width: 40%;
    		margin-top: 10px;
		}
	}
	.centerButton{
		width: 100%;
		text-align: center; 
		position: absolute;
		top: 50%;
		color: #fff;
		left: 50%;
		font-size: 1.2em;
		*{
			color: #fff;
			font-family: 'Playfair';
		}
		@include transform($center);
	}
}

.WelcomeText .WelcomeCalButton,
	.WelcomeText .WelcomeButton{
		right: 110px;
	}
	.WelcomeText.WelcomeCalButton{
	    right: 0px;
	}
	*/
}
